import React from "react"
import AccountCredit from "../../components/body/pages/incentive-program/accountCredit"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const CreditAccount = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/account-credit"}
      title="Kuda Incentive Program"
    />
    <AccountCredit />
  </NoheaderFooter>
)
export default CreditAccount
